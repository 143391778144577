<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmerrorcodes.title')">
					<v-row no-gutters>
						<v-col>
							<v-row no-gutters>
								<v-col cols="12" lg="6" xl="4">
									<pui-text-field
										:label="$t('pmerrorcodes.acronym')"
										v-model="model.acronym"
										required
										toplabel
										maxlength="150"
									></pui-text-field>
								</v-col>
								<v-col cols="12" lg="6" xl="4">
									<pui-text-field
										:label="$t('pmerrorcodes.message')"
										v-model="model.message"
										required
										toplabel
										maxlength="150"
									></pui-text-field>
								</v-col>
								<v-col cols="12" xl="4">
									<pui-text-field
										:label="$t('pmerrorcodes.legend')"
										v-model="model.legend"
										toplabel
										maxlength="100"
									></pui-text-field>
								</v-col>
								<v-col cols="12">
									<pui-text-area
										v-model="model.description"
										:label="$t('pmerrorcodes.description')"
										maxlength="250"
										toplabel
									></pui-text-area>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" md="5" lg="4" xl="3">
							<v-row no-gutters class="height-100 width-100">
								<pui-field-set :title="$t('pmseverity.color')" class="height-100 width-100">
									<v-col cols="12">
										<v-color-picker
											v-model="model.color"
											dot-size="15"
											hide-mode-switch
											mode="hexa"
											elevation="2"
											canvas-height="100"
											:label="$t('pmseverity.color')"
											class="ma-4"
											required
											@input="validColor"
										></v-color-picker>
									</v-col>
									<v-col cols="12">
										<pui-checkbox label="Set to NULL" v-model="colorNull"></pui-checkbox>
									</v-col>
								</pui-field-set>
							</v-row>
						</v-col>
					</v-row>

					<v-row no-gutters>
						<v-col cols="12" md="2">
							<pui-checkbox
								:label="$t('pmerrorcodes.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								:class="$vuetify.breakpoint.sm ? 'mt-2' : 'mt-n4'"
							></pui-checkbox>
						</v-col>
					</v-row>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="checkColor"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import { search } from '@/api/common';

export default {
	name: 'pmerrorcodesform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'pmerrorcodes',
			model: 'pmerrorcodes',
			usedColors: [],
			colorNull: 0
		};
	},
	computed: {
		isUpdateEntry() {
			return typeof this.model == 'object' && !!this.model.pmerrorcodesid;
		}
	},
	watch: {
		'model.color': {
			handler(newValue, oldValue) {
				console.log('debug color', oldValue, newValue);
				if (newValue && typeof newValue == 'object') {
					this.model.color = this.model.color.hex;
				}
			}
		},
		colorNull(val, prev) {
			console.log('colorNull', prev, val);
		},
		isUpdateEntry(val) {
			console.log('isupdateentry watcher', val);
			this.getColors();
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	mounted() {},
	updated() {},
	methods: {
		checkColor() {
			if (this.colorNull) {
				this.model.color = null;
				this.save();
			} else {
				if (this.model.color.length == 9) this.model.color = this.model.color.substring(0, 7);
				if (!this.validColor(this.model.color)) return;
				this.save();
			}
		},
		validColor(value) {
			if (this.usedColors.includes(value)) {
				this.$puiNotify.error('Color code already in use', 'Error');
				return false;
			}
			return true;
		},
		async getColors() {
			console.debug('debug acronym', this.model, this.model.acronym);
			let filterUser = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'color', op: 'nn' },
					{ field: 'pmerrorcodesid', op: 'ne', data: this.model.pmerrorcodesid }
				]
			};
			let body = {
				model: 'pmerrorcodes',
				rows: 1000,
				filter: filterUser
			};

			let { data } = await search(body);
			//console.log('After get data - Rules', data.data);
			this.usedColors = data.data.map((errorCode) => {
				return errorCode.color;
			});
			console.log('used colors', this.usedColors);
		}
	}
};
</script>
